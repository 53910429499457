// import { UserInfo } from "apiHelpers/user";

export const SET_COPIES: string = "SET_COPIES";
export const SET_EDITOR_STATE: string = "SET_EDITOR_STATE";
export const SET_INPUTS: string = "SET_INPUTS";
export const SET_GENERATIONS: string = "SET_GENERATIONS";
export const SET_TOGGLE_RESULTS: string = "SET_TOGGLE_RESULTS";
export const SET_SIDEBAR_OPEN: string = "SET_SIDEBAR_OPEN";
export const SET_PLAGIARISM_OPEN: string = "SET_PLAGIARISM_OPEN";
export const SET_PLAGIARISM: string = "SET_PLAGIARISM";
export const GET_SOP_ID: string = "GET_SOP_ID";
export const GET_SOP_TITLE: string = "GET_SOP_TITLE";
export const SET_SOP_TITLE: string = "SET_SOP_TITLE";
export const SET_SOP_LENGTH: string = "SET_SOP_LENGTH";

export const setCopies = (copies?: Array<any>) => {
  return { type: SET_COPIES, payload: copies };
};

export const setInputs = (inputs: any) => {
  return { type: SET_INPUTS, payload: { ...inputs } };
};

export const setEditorState = (editorState: string) => {
  return { type: SET_EDITOR_STATE, payload: editorState };
};
export const setSopLength = (length: string) => {
  return { type: SET_SOP_LENGTH, payload: length };
};

export const setGenerations = (generations: any) => {
  return { type: SET_GENERATIONS, payload: generations };
};

export const setToggleResults = (toggleResults: boolean) => {
  return { type: SET_TOGGLE_RESULTS, payload: toggleResults };
};
export const setSidebarOpen = (sidebarOpen: boolean) => {
  return { type: SET_SIDEBAR_OPEN, payload: sidebarOpen };
};

export const setPlagiarismOpen = (PlagiarismOpen: boolean) => {
  return { type: SET_PLAGIARISM_OPEN, payload: PlagiarismOpen };
};

export const setPlagiarism = (loading: boolean) => {
  return { type: SET_PLAGIARISM, payload: loading };
};

export const getSopId = (sopId: string) => {
  return { type: GET_SOP_ID, payload: sopId };
};

export const getSopTitle = (sopTitle: string) => {
  return { type: GET_SOP_TITLE, payload: sopTitle };
};

export const setSopTitle = (sopTitle: string) => {
  return { type: SET_SOP_TITLE, payload: sopTitle };
};

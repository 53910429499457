import { combineReducers } from "redux";
import main, { MainState } from "./main/reducer";
import sop, { SOPState } from "./sop/reducers";

export interface GlobalState {
  sop: SOPState;
  main: MainState;
}

export default combineReducers({
  main,
  sop,
});

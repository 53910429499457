import {
  SET_COPIES,
  SET_EDITOR_STATE,
  SET_GENERATIONS,
  SET_INPUTS,
  SET_SIDEBAR_OPEN,
  SET_TOGGLE_RESULTS,
  SET_PLAGIARISM_OPEN,
  SET_PLAGIARISM,
  GET_SOP_ID,
  GET_SOP_TITLE,
  SET_SOP_TITLE,
  SET_SOP_LENGTH,
} from "./actions";
import { getPlagiarism } from "../../apiFunctions/sop/utils";

export interface SOPState {
  copies: Notification | undefined;
  editorState: string;
  toggleResults: boolean;
  sideBarOpen: boolean;
  plagiarismOpen: boolean;
  loading: boolean;
  sop_id: string;
  sop_title: string;
  length: string;
}

const INITIAL_STATE: SOPState = {
  copies: undefined,
  editorState: "",
  toggleResults: false,
  sideBarOpen: false,
  plagiarismOpen: false,
  loading: false,
  sop_id: "",
  sop_title: "",
  length: "medium",
};

export default function sop(
  state = INITIAL_STATE,
  action: { type: any; payload: any }
) {
  switch (action.type) {
    case SET_COPIES:
      return { ...state, copies: action.payload };
    case SET_INPUTS:
      return { ...state, inputs: action.payload };
    case SET_EDITOR_STATE:
      return { ...state, editorState: action.payload };
    case SET_SOP_LENGTH:
      return { ...state, length: action.payload };
    case SET_GENERATIONS:
      return { ...state, generations: action.payload };
    case SET_TOGGLE_RESULTS:
      return { ...state, toggleResults: action.payload };
    case SET_SIDEBAR_OPEN:
      return { ...state, sidebarOpen: action.payload };
    case SET_PLAGIARISM_OPEN:
      return { ...state, plagiarismOpen: action.payload };
    case SET_PLAGIARISM:
      return { ...state, loading: action.payload };
    case GET_SOP_ID:
      return { ...state, sop_id: action.payload };
    case GET_SOP_TITLE:
      return { ...state, sop_title: action.payload };
    case SET_SOP_TITLE:
      return { ...state, sop_title: action.payload };
    default:
      return state;
  }
}

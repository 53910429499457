declare global {
  interface Window {
    $crisp: any[];
  }
}
import "../styles/globals.css";
import { useRouter } from "next/router";
import { useEffect } from "react";
import type { AppProps } from "next/app";
import { firebaseConfig } from "../utils/firebaseUtils";
import { getApps, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "../store/reducers";
import { Provider } from "react-redux";
import Main from "./main";
import Script from "next/script";
import Head from "next/head";
import PlausibleProvider from "next-plausible";

if (!getApps().length) {
  initializeApp(firebaseConfig);
}
const preloadedState = {};
const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== "production",
  preloadedState,
});

function MyApp(props: any) {
  const router = useRouter();

  useEffect(() => {
    console.log(router.pathname);

    if (router.pathname === "/sop/pricing") {
      const timer = setTimeout(() => {
        window.$crisp.push(["do", "chat:open"]);
        // window.$crisp.push([
        //   "do",
        //   "message:show",
        //   [
        //     "text",
        //     "Invest in our product and you won't regret it - it's worth every penny!",
        //   ],
        // ]);
      }, 7000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [router.pathname]);

  return (
    // add plausible provider domain as tootler.ai
    <PlausibleProvider
      domain="tootler.ai"
      customDomain="https://plausible.tootler.ai"
      selfHosted={true}
      enabled={true}
    >
      <Provider store={store}>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SHP4ESQYT8"
        ></script>
        <Script
          id="GA"
          dangerouslySetInnerHTML={{
            __html: ` window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-SHP4ESQYT8');`,
          }}
        ></Script>
        <Script
          id="helpdesk"
          dangerouslySetInnerHTML={{
            __html: ` window.$crisp=[];window.CRISP_WEBSITE_ID="f49a3e9e-4660-4227-ae26-4835f0446510";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
          }}
        ></Script>

        <Main {...props} />
      </Provider>
    </PlausibleProvider>
  );
}

export default MyApp;

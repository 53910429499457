// import { UserInfo } from "apiHelpers/user";

import { SOP } from "../../apiFunctions/sop/utils";

export const SET_NOTIFICATION: string = "SET_NOTIFICATION";
export const SET_USER_PROFILE: string = "SET_USER_PROFILE";

export enum NotificationStatus {
  success,
  warning,
  failed,
}

export interface Notification {
  message: string;
  status: NotificationStatus;
}

export interface UserInfo {
  fb_id: string;
  full_name: string;
  email: string;
  photo_url: string;
  sop_credits: number;
  sop_histories?: Array<SOP>;
  sop_type: string;
  unlimited_plan: boolean;
  sop_plan_id: string;
  sop_plan: {
    id: string;
    title: string;
    price: number;
    description: string;
    type: string;
    features: Array<string>;
  };
}

export const setNotification = (notification: Notification) => {
  return { type: SET_NOTIFICATION, payload: notification };
};

export const setUser = (user: any) => {
  return { type: SET_USER_PROFILE, payload: user };
};

import { NextComponentType, NextPage, NextPageContext } from "next";
import { ReactElement, ReactNode, useEffect } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Notification, NotificationStatus } from "../store/main/actions";
import { GlobalState } from "../store/reducers";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";
import DiscountBanner from "../components/generic/DiscountBanner";

export type NextPageWithLayout<P = {}> = NextPage<P, P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MainProps {
  Component: NextComponentType<NextPageContext, any, {}> & NextPageWithLayout;
  pageProps: any;
  notification: Notification | undefined;
}

export async function getServerSideProps() {
  return {
    props: {},
  };
}

const Main: React.FC<MainProps> = ({ Component, pageProps, notification }) => {
  useEffect(() => {
    switch (notification && notification.status) {
      case NotificationStatus.success:
        toast.success(notification?.message);
        break;
      case NotificationStatus.warning:
        toast.warn(notification?.message);
        break;
      case NotificationStatus.failed:
        toast.error(notification?.message);
    }
  }, [notification]);

  return (
    <>
      <ToastContainer
        className="z-50"
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Component {...pageProps} />
    </>
  );
};

const mapStateToPros = (state: GlobalState) => {
  return {
    notification: state.main?.notification,
  };
};

export default connect(mapStateToPros)(Main);

import { Notification, SET_NOTIFICATION, SET_USER_PROFILE } from "./actions";

export interface MainState {
  notification: Notification | undefined;
}

const INITIAL_STATE: MainState = {
  notification: undefined,
};

export default function main(
  state = INITIAL_STATE,
  action: { type: any; payload: any }
) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { ...state, notification: action.payload };
    case SET_USER_PROFILE:
      return { ...state, userInfo: action.payload };

    default:
      return state;
  }
}
